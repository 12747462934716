import React, { useState } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import styled from "styled-components";
import AnimImage from "./animateImage";

const SubscribeSection = () => {
  const [inputs, setInputs] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    emailError: "",
  });

  const handleOnChange = (event) => {
    event.persist();
    setInputs((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const checkEmailInput = (event) => {
    event.persist();
    if (inputs.email.length === 0) {
      setErrors((prev) => ({
        ...prev,
        emailError: "E-mail is required ",
      }));
    } else if (inputs.email.length < 2 || re.test(inputs.email) === false) {
      setErrors((prev) => ({
        ...prev,
        emailError: "Please provide a valid e-mail address.",
      }));
    } else {
      setTimeout(() => {
        setErrors((prev) => ({
          ...prev,
          emailError: "",
        }));
      }, 500);
    }
  };
  console.log(process.env.GATSBY_FORMSPREE_SUBSCRIBE_FORM_ID);
  const handleOnSubmit = (event) => {
    event.preventDefault();
    setServerState({ submitting: true });

    axios({
      method: "POST",
      url: `https://formspree.io/f/${process.env.GATSBY_FORMSPREE_SUBSCRIBE_FORM_ID}`,
      data: inputs,
    })
      .then((r) => {
        handleServerResponse(true, "Thanks!");
        navigate("/thank-you");
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error);
      });
  };
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      setInputs({
        email: "",
      });
    }
  };
  return (
    <AnimImage>
      <SubscribeWrapper>
        <SubscribeForm onSubmit={handleOnSubmit} method="post">
          <FormCta>
            <h5>Register for Updates</h5>
          </FormCta>
          <InputContainer>
            <StyledInput
              placeholder="Enter your email here"
              onChange={handleOnChange}
              onBlur={checkEmailInput}
              type="email"
              id="email"
            />
          </InputContainer>
          <StyledButton type="submit">Submit</StyledButton>
          <FormTextError error={errors.emailError}>
            <span>{errors.emailError}</span>
          </FormTextError>
        </SubscribeForm>
      </SubscribeWrapper>
    </AnimImage>
  );
};

const SubscribeWrapper = styled.div`
  padding: 150px 0;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  position: relative;
  @media (max-width: 768px) {
    padding: 100px 0;
  }
  @media (max-width: 470px) {
    padding: 50px 0;
  }
`;

const SubscribeForm = styled.form`
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
`;

const FormCta = styled.div`
  padding: 12px 40px;

  h5 {
    font-size: 31px;
    line-height: 36px;
    font-family: poppins;
    font-weight: 600;
  }
`;

const StyledButton = styled.button`
  background: transparent;
  font-size: 18px;
  font-family: poppins;
  outline: none;
  border: none;
  color: #80ed99;
  font-weight: 800;
  font-style: italic;
  cursor: pointer;
  width: 70%;
  margin: 0px 40px;
  text-align: right;
  transition: all 0.3s ease-in-out;

  :hover {
    color: #e8ecf3;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;
`;

const StyledInput = styled.input`
  width: 70%;
  background: transparent;
  border: none;
  outline: none;
  margin: 0px 40px 20px;
  font-size: 18px;
  font-family: IBM Plex Mono;
  font-style: italic;
  color: #e8ecf3;
  font-weight: 400;
  padding-bottom: 4px;
  box-sizing: border-box;
  padding: 3px 3px 12px 12px;
  border-bottom: 1px solid #e8ecf3;
`;

const FormTextError = styled.div`
  height: 30px;
  width: 500px;
  margin: 0 40px 20px;
  position: relative;
  overflow: hidden;
  span {
    color: #e8ecf3;
    bottom: ${({ error }) => {
      if (error === "") return "26px";
      if (error !== "") return "0";
    }};
    opacity: ${({ error }) => {
      if (error === "") return "0";
      if (error !== "") return "1";
    }};
    display: block;
    position: absolute;
    transition: all 1s ease-in-out;
    font-family: IBM Plex Mono;
    font-size: 14px;
  }
`;

export default SubscribeSection;
