import React from "react";

import Layout from "../components/layout";
import Header from "../components/header";
import { Container } from "../components/layoutComponents";
import ProductsInfo from "../components/productsInfo";
import FeaturesSection from "../components/featuresSection";
import SEO from "../components/seo";
import Faq from "../components/faq";
import SubscribeSection from "../components/subscribeSection";

const SeoData = {
  title: "Tabletop Streamer",
  description: "Streaming software for tabletop wargames",
  keywords: [`Tabletop Streamer`, `application`, `react`],
  image: "/seo-preview.jpeg",
  url: "https://www.tabletopstreamer.com/",
  titleTemplate: "Tabletop Streamer",
};

const HeaderData = {
  title: "Tabletop Streamer",
  description: "Streaming software for tabletop wargames",
};

const ProductsInfoData = {
  desktop: {
    title: "Helpful Graphics",
    description:
      "Add AR content to your streams or battle reports with a simple drag & drop interface.<br /> <br />Import lists from Battlescribe, add virtual wound counters, aura bubbles, terrain callouts, and more.<br /> <br /> Built-in drawing tools make it easy to mark-up the battlefield with tactical annotations, measurements, and even custom emojis.",
    image: "/desktop.webp",
    videoSrc: "desktop-video.mp4",
  },
  mobile: {
    title: "AR Companion App",
    description:
      "Get the best view of the battle with the AR client app, coming soon for iOS and Android. <br /> <br /> Join a game session with the desktop app to view and stream the same AR content, then switch views on the fly.",
    image: "/mobile.webp",
    videoSrc: "mobile-video.mp4",
  },
};

const FeaturesSectoion = {
  featuresList: [
    {
      iconName: "battlescribe",
      title: "Battlescribe import",
      description:
        "Import army lists created in Battlescribe. Supports custom names, starting Command Points, relics, and more.",
    },
    {
      iconName: "automatic-clips",
      title: "Automatic recap clips",
      description:
        "Take a snapshot after each phase of the game to automatically generate 'before and after' recap clips, highlighting all units that moved, charged, or were destroyed.",
    },
    {
      iconName: "scoreboard",
      title: "Scoreboard",
      description:
        "Track primary and secondary mission objectives, including unique codex & mission secondaries. <br /> <br /> Customize the look and feel of the scoreboard to match your channel's branding.",
    },
    {
      iconName: "drawing-tools",
      title: "Drawing tools and callouts",
      description:
        "Draw attention to specific areas, units, and fights with built-in drawing tools. <br /> <br />Provide at-a-glance overviews of objective markers and who controls them. Call out terrain features and more.",
    },
    {
      iconName: "health-bar",
      title: "Wound trackers and health bars",
      description:
        "Quickly visualize the momentum of the battle with integrated wound counters. <br /> <br /> View remaining wounds in AR, or in a condensed list view, to easily see how much of each army remains.",
    },
    {
      iconName: "effects",
      title: "Special effects",
      description:
        "Add a new layer of immersion with special effects including tracer fire, smoke, and explosions when units are destroyed.<br /> <br /> Coming soon: use AR view to place your battles inside immersive backdrops, including ruined cities, Xenos hive fleets, or the marvels of the Warp.",
    },
  ],
};

const IndexPage = () => (
  <Layout>
    <Container>
      <SEO
        title={SeoData.title}
        keywords={SeoData.keywords}
        description={SeoData.description}
        url={SeoData.url}
        titleTemplate={SeoData.titleTemplate}
        image={SeoData.image}
      />
      <Header title={HeaderData.title} description={HeaderData.description} />
      <ProductsInfo
        desktopInfo={ProductsInfoData.desktop}
        mobileInfo={ProductsInfoData.mobile}
      />
      <FeaturesSection featuresListData={FeaturesSectoion.featuresList} />
      <Faq />
      <SubscribeSection />
    </Container>
  </Layout>
);

export default IndexPage;
