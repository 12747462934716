import React, { Component } from "react";
import ReactPlayer from "react-player/lazy";
import styled from "styled-components";
import { Waypoint } from "react-waypoint";

export default class InlineVideo extends Component {
  state = {
    playing: false,
  };

  render() {
    return (
      <Waypoint
        onEnter={() => this.setState({ playing: true })}
        onLeave={() => this.setState({ playing: false })}
      >
        <WrapperIframe className="wrapper-iframe">
          <ContainerIframe
            onClick={() => this.setState({ playing: !this.state.playing })}
          >
            <ReactPlayer
              className="responsive-iframe"
              url={this.props.videoSrc}
              playing={this.state.playing}
              muted={true}
              loop
              playsinline
            />
          </ContainerIframe>
          <ButtonPlay
            onClick={() => this.setState({ playing: !this.state.playing })}
            playing={this.state.playing}
            aria-label="play"
          >
            <IconButton playing={this.state.playing} />
          </ButtonPlay>
        </WrapperIframe>
      </Waypoint>
    );
  }
}

const ContainerIframe = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  cursor: pointer;

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;

const ButtonPlay = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: ${(props) => (props.playing === true ? "0" : "1")};
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: black;
  z-index: 10000;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
`;

const IconButton = styled.div`
  top: 50%;
  left: ${(props) => (props.playing === true ? "50%" : "55%")};
  transform: translate(-50%, -50%);
  content: "";
  position: absolute;
  width: 12px;
  height: 16px;
  border-bottom: ${(props) =>
    props.playing === true ? "none" : "10px solid transparent"};
  border-top: ${(props) =>
    props.playing === true ? "none" : "10px solid transparent"};
  border-left: ${(props) =>
    props.playing === true
      ? "4px solid  rgb(67,68,72)"
      : "14px solid  rgb(67,68,72)"};
  border-right: ${(props) =>
    props.playing === true ? "4px solid  rgb(67,68,72)" : ""};
`;

const WrapperIframe = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  video {
    border-radius: 10px;
  }
`;

/*

<ButtonPlay onClick={() => this.setState({ playing: !this.state.playing })} playing={this.state.playing} >
    <IconButton playing={this.state.playing} />
</ButtonPlay>


*/
