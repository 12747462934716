import React from "react";
import Collapsible from "react-collapsible";
import styled from "styled-components";

const FaqItem = ({ title, children }) => (
  <Wrapper>
    <Collapsible
      className="faq"
      openedClassName="faq active"
      triggerClassName="faq-title"
      triggerOpenedClassName="faq-title active"
      triggerTagName="button"
      contentInnerClassName="faq-content"
      trigger={title}
      transitionTime={300}
      easing="ease-out"
    >
      {children}
    </Collapsible>
  </Wrapper>
);

const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
  .faq {
    border-bottom: 2px solid #80ed99;
  }
  .faq-title {
    border: none;
    background: none;
    padding: 0;
    outline: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-family: poppins;
    font-weight: bold;
    padding: 24px;
    padding-right: 72px;
    padding-left: 16px;
    position: relative;

    @media (max-width: 470px) {
      padding-right: 40px;
      padding-left: 0;
    }
    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-left: 2px solid #80ed99;
      border-bottom: 2px solid #80ed99;
      position: absolute;
      top: 34px;
      right: 36px;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out;

      @media (max-width: 470px) {
        right: 18px;
      }
    }
    &.active {
      &::after {
        transform: rotate(135deg);
      }
    }
  }
  .faq-content {
    padding: 32px 72px 32px 16px;
    color: #80ed99;
    text-align: left;
    font-family: IBM Plex Mono;
    letter-spacing: 0em;
    line-height: 1.6;
    font-weight: 300;
    font-size: 16px;
  }
`;

export default FaqItem;
