import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Container, Row } from "./layoutComponents";
import TtsIcons from "./ttsIcons";

const FeaturesSection = ({
  featuresListData,
  staggerChildren = 0.1,
  triggerOnce = true,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    rootMargin: "-150px",
    triggerOnce,
  });

  useEffect(() => {
    if (inView) {
      controls.start("active");
    }
    if (!inView) {
      controls.start("pre");
    }
  }, [controls, inView]);
  const animation = {
    timingFunction: {
      js: [0.1, 0.25, 0.3, 1],
      css: `cubic-bezier(0.1, 0.25, 0.3, 1)`,
    },
    duration: {
      100: {
        js: 0.3,
        css: `300ms`,
      },
      200: {
        js: 0.5,
        css: `500ms`,
      },
      300: {
        js: 0.6,
        css: `600ms`,
      },
    },
  };

  const textContainerVariants = {
    pre: {},
    active: {
      transition: {
        staggerChildren,
        duration: 2,
        ease: [animation.timingFunction.js],
      },
    },
  };

  const textVariants = {
    pre: {
      opacity: 0,
      rotate: "-3deg",
      y: "140%",
    },
    active: {
      opacity: 1,
      rotate: "0deg",
      y: "0%",
      transition: {
        duration: 0.9,
        ease: [animation.timingFunction.js],
      },
    },
  };
  return (
    <StyledContainer>
      <WrapperContent
        ref={ref}
        variants={textContainerVariants}
        initial="pre"
        animate={controls}
      >
        <Row wrap="wrap">
          {featuresListData.map((feature) => {
            return (
              <WrapperFeature variants={textVariants} key={feature.title}>
                <FeatureItem>
                  <IconWrapper>
                    <TtsIcons type={feature.iconName} />
                  </IconWrapper>
                  <h5> {feature.title}</h5>

                  <p
                    dangerouslySetInnerHTML={{ __html: feature.description }}
                  ></p>
                </FeatureItem>
              </WrapperFeature>
            );
          })}
        </Row>
      </WrapperContent>
    </StyledContainer>
  );
};

const WrapperContent = styled(motion.div)`
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: var(--primary-font);
  font-family: poppins;
  justify-content: space-between;

  @media (max-width: 1240px) {
    padding: 0 32px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const StyledContainer = styled(Container)`
  max-width: 1440px;
  padding: 0;
`;

const WrapperFeature = styled(motion.div)`
  width: 50%;
  display: flex;
  justify-content: center;

  @media (max-width: 470px) {
    width: 100%;
  }
`;

const FeatureItem = styled.div`
  width: 100%;
  margin-bottom: 40px;
  margin-top: 20px;
  max-width: 570px;

  @media (max-width: 1240px) {
    max-width: 480px;
    padding: 0 24px;
  }
  @media (max-width: 768px) {
    max-width: 310px;
    padding: 0;
  }
  h5 {
    font-size: 20px;
    font-family: poppins;
    font-style: normal;
    text-align: left;
    letter-spacing: 0.01em;
    line-height: 1.2;
    color: var(--primary-font);
    font-weight: 600;
  }
  p {
    font-size: 16px;
    font-weight: 200;
    font-family: IBM Plex Mono;
    max-width: 470px;
    line-height: 1.8;
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 8px;

  svg {
    width: 45px;
    height: 45px;
  }
`;

export default FeaturesSection;
