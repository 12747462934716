import React from "react";
import styled from "styled-components";
import { Container } from "./layoutComponents";
import TtsIcons from "./ttsIcons";

const Header = ({ title, description }) => (
  <Container>
    <WrapperContent>
      <LogoWrapper>
        <TtsIcons type="tts-logo" />
      </LogoWrapper>
      <h2>{title}</h2>
      <span>{description}</span>
    </WrapperContent>
  </Container>
);

const WrapperContent = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary-font);
  font-family: poppins;
  text-align: center;
  @media (max-width: 470px) {
    margin-top: 50px;
  }
  h2 {
    font-size: 28px;
    letter-spacing: 0em;
    line-height: 1.3;
    margin-bottom: 15px;
    font-family: poppins;
    font-wight: 600;
  }

  span {
    font-weight: 300;
    font-size: 16px;
    font-family: IBM Plex Mono;
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: 32px;

  svg {
    width: 140px;
    height: 140px;
  }
  @media (max-width: 470px) {
    svg {
      width: 80px;
      height: 80px;
    }
  }
`;

export default Header;
