import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import useWindowSize from "../hooks/useWindowSize";

const AnimImage = ({ children, delay, top }) => {
  const controls = useAnimation();
  const size = useWindowSize();
  const [ref, inView] = useInView({
    rootMargin: "-50px",
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.6,
            delay: 0.2,
            ease: [0.6, 0.05, -0.01, 0.9],
          },
        },
        hidden: { opacity: 0, y: size.width > 425 ? 100 : 60 },
      }}
    >
      {children}
    </motion.div>
  );
};

export default AnimImage;
