import React from "react";
import styled from "styled-components";
import { Container, Row } from "./layoutComponents";
import InlineVideo from "./inlineVideo";
import AnimText from "./animateText";
import AnimImage from "./animateImage";

const ProductsInfo = ({ desktopInfo, mobileInfo }) => (
  <>
    <StyledDesktopContainer>
      <WrapperContent>
        <StyledRow justifyContent="center">
          <AnimText>
            <ColumnDesktop>
              <h2>{desktopInfo.title}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: desktopInfo.description }}
              ></div>
            </ColumnDesktop>
          </AnimText>

          <ImageContainer>
            <AnimImage>
              <img
                width="627"
                height="492"
                src={desktopInfo.image}
                alt={desktopInfo.title}
              />
              <VideoContainer>
                <InlineVideo videoSrc={desktopInfo.videoSrc} />
              </VideoContainer>
            </AnimImage>
          </ImageContainer>
        </StyledRow>
      </WrapperContent>
    </StyledDesktopContainer>
    <StyledContainer>
      <WrapperContent>
        <MobileRow flexDirection="row-reverse" justifyContent="center">
          <AnimText>
            <ColumnMobile>
              <h2>{mobileInfo.title}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: mobileInfo.description }}
              ></div>
            </ColumnMobile>
          </AnimText>
          <AnimImage>
            <ImageMobContainer>
              <img
                src={mobileInfo.image}
                alt={mobileInfo.title}
                width="721"
                height="363"
              />
              <MobileVideoContainer>
                <InlineVideo videoSrc={mobileInfo.videoSrc} />
              </MobileVideoContainer>
            </ImageMobContainer>
          </AnimImage>
        </MobileRow>
      </WrapperContent>
    </StyledContainer>
  </>
);

const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: var(--primary-font);
  font-family: poppins;

  h2 {
    font-size: 36px;
    font-style: normal;
    text-align: left;
    letter-spacing: 0.01em;
    line-height: 1.2;
    color: var(--primary-font);
    font-weight: 600;
    margin-bottom: 50px;
    font-family: poppins;
  }
`;

const ColumnDesktop = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 0.6%;
  margin-bottom: 0.6%;

  @media (max-width: 1920px) {
    max-width: 400px;
  }
  @media (max-width: 1240px) {
    max-width: 100%;
    margin-bottom: 32px;
  }
  div {
    font-family: IBM Plex Mono;
    letter-spacing: 0em;
    line-height: 1.6;
    font-weight: 300;
  }
`;

const ColumnMobile = styled.div`
  width: 100%;
  max-width: 470px;
  display: flex;
  flex-direction: column;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 0.6%;
  margin-bottom: 0.6%;

  div {
    font-family: IBM Plex Mono;
    letter-spacing: 0em;
    line-height: 1.6;
    font-weight: 300;
  }

  @media (max-width: 1240px) {
    margin-top: 32px;
  }
`;

const ImageContainer = styled.div`
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 0.6%;
  position: relative;
  overflow: hidden;
  width: 60%;
  max-width: 1050px;

  @media (max-width: 1240px) {
    width: 100%;
    margin: 0 auto;
  }
  img {
    width: 100%;
    height: auto;
    z-index: 0;
    margin-bottom: 0;
  }
`;

const ImageMobContainer = styled.div`
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 0.6%;
  position: relative;
  overflow: hidden;

  @media (max-width: 470px) {
    max-width: 320px;
  }
  img {
    width: 100%;
    height: auto;
    z-index: 0;
    margin-bottom: 0;
  }
`;

const StyledRow = styled(Row)`
  padding: 100px 0;
  max-width: 1900px;

  @media (max-width: 1240px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 768px) {
    padding: 50px 0;
  }
  @media (max-width: 470px) {
    padding: 24px 0;
  }
`;

const MobileRow = styled(Row)`
  padding: 100px 0;
  width: 100%;
  margin: 0 -40px;

  @media (max-width: 1240px) {
    flex-direction: column-reverse;
    margin: 0 auto;
    max-width: 800px;
  }
`;

const StyledContainer = styled(Container)`
  max-width: 1440px;
  padding: 0;
`;

const StyledDesktopContainer = styled(Container)`
  max-width: 1900px;
  padding: 0;
`;

const VideoContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0%;
  overflow: hidden;
`;

const MobileVideoContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0%;
  overflow: hidden;
  height: auto;
  .wrapper-iframe {
    border-radius: 30px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 0;
    max-height: 306px;
    position: relative;
    z-index: 100000;

    video {
      border-radius: 30px;
      @media (max-width: 1240px) {
        border-radius: 32px;
      }
      @media (max-width: 540px) {
        border-radius: 22px;
      }
      @media (max-width: 470px) {
        border-radius: 22px;
      }
      @media (max-width: 375px) {
        border-radius: 16px;
      }
      @media (max-width: 320px) {
        border-radius: 8px;
      }
    }
    @media (max-width: 1240px) {
      max-height: 338px;
      border-radius: 32px;
      margin-top: 24px;
    }
    @media (max-width: 768px) {
      max-height: 294px;
    }
    @media (max-width: 540px) {
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      max-height: 206px;
      border-radius: 22px;
    }
    @media (max-width: 470px) {
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      max-height: 142px;
      border-radius: 22px;
    }
    @media (max-width: 375px) {
      max-height: 130px;
      border-radius: 16px;
    }
    @media (max-width: 360px) {
      max-height: 124px;
    }
    @media (max-width: 320px) {
      max-height: 103px;
      border-radius: 8px;
    }
    @media (max-width: 280px) {
      margin-top: 8px;
      margin-left: 8px;
      margin-right: 8px;
      max-height: 86px;
    }
  }
`;

export default ProductsInfo;
